<template>
  <el-container>
    <el-dialog
      width="800px"
      :visible.sync="chukuDlg_isShow"
      title="添加新的出库"
      :close-on-click-modal="false"
    >
      <chuku-form
        :cailiaotongzhidans="cailiaotongzhidans"
        ref="chukuForm"
        @submitForm="submitwuziChukuForm"
        @resetForm="resetChukuForm"
      ></chuku-form>
    </el-dialog>
    <!-- 库房出库显示照片 -->
    <el-dialog
      width="1000px"
      :visible.sync="chukuPhotos_isShow"
      :close-on-click-modal="false"
    >
      <div class="demo-image__lazy">
        <el-image
          v-for="url in chukuphotosurls"
          :key="url"
          :src="url"
        ></el-image>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="chukuPhotos_isShow = false">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 接收出库单据照片显示 -->
    <el-dialog
      width="1000px"
      :visible.sync="receivechukuPhotos_isShow"
      :close-on-click-modal="false"
    >
      <div class="demo-image__lazy">
        <el-image
          v-for="url in receivechukuphotosurls"
          :key="url"
          :src="url"
        ></el-image>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="receivechukuPhotos_isShow = false">关 闭</el-button>
      </span>
    </el-dialog>
    <el-dialog
      width="40%"
      :visible.sync="receiveChukuDlg_isShow"
      title="上传接收单据"
      :close-on-click-modal="false"
    >
      <receive-form
        @submitReceiveForm="submitReceiveForm"
        @resetForm="resetReceiveForm"
      ></receive-form>
    </el-dialog>

    <el-header style="height:30px">
      <div class="left">
        <el-button
          type="primary"
          v-if="usertype.code === '6' || usertype.code === '4'"
          @click="chukuDlg_isShow = true"
        >
          添加新的出库</el-button
        >
        <el-button type="primary"> 导出数据</el-button>
        <el-radio-group v-model="chukuxinxiview" @change="setChukuxinxiView">
          <el-radio :label="1">按合同显示</el-radio>
          <el-radio :label="2">按库房显示</el-radio>
        </el-radio-group>
      </div>
      <div class="right">
        <el-input
          placeholder="项目 | 工地 | 库房 | 材料"
          v-model="keyword"
          @keyup.enter.native="getTableData()"
        >
          <el-button slot="append" @click="getTableData()"> 查询 </el-button>
        </el-input>
      </div>
    </el-header>
    <el-main>
      <!-- <download-excel class="button" :data="tableData" :fields="json_fields" name="UserInfo.xlsx">
        <button>导出数据</button>
      </download-excel> -->
      <chuku-table
        :tableData="tableData"
        :userType="usertype"
        :config="config"
        @showPhotos="showPhotosinTableChuku"
        @changePage="getTableData"
        @doReceive="doReceive"
        @showReceivePhotos="showReceivePhotos"
        @del="delKufangchuku"
        @Wuzi_shenhe="Wuzi_shenhe"
        @Wuzi_shenheJieshou="Wuzi_shenheJieshou"
      ></chuku-table>
    </el-main>
  </el-container>
</template>

<script>
import ChukuForm from './wuziChukuForm'
import ChukuTable from './wuziChukuTable'
import ReceiveForm from './receiveForm'
import utils from '@/assets/js/utils'
export default {
  components: {
    ChukuForm,
    ChukuTable,
    ReceiveForm
  },
  data() {
    return {
      chukuForm: {},
      receiveForm: {},
      chukuDlg_isShow: false,
      receiveChukuDlg_isShow: false,
      keyword: '',
      usertype: '',
      tableData: [],
      fileListReceiveChuku: [],
      chukuPhotos_isShow: false,
      receivechukuPhotos_isShow: false,
      chukuphotosurls: [],
      receivechukuphotosurls: [],
      cailiaotongzhidans: [],
      company: utils.getCompanyCookie(),
      chukuxinxiview: 1,

      config: {
        page: 1,
        total: 30,
        loading: false
      }
    }
  },
  methods: {
    getTableData() {
      this.config.loading = true
      console.log('keyword:', this.keyword)
      console.log('page:', this.config.page)
      const api = '/getChukuxinxis/'
      this.$axios
        .get(api, {
          params: {
            chukuxinxiview: this.chukuxinxiview, //出库信息查询方式
            company: this.company, //当前用户所属分公司编号code
            page: this.config.page,
            keyword: this.keyword
          }
        })
        .then(res => {
          if (res.data.code === '1000') {
            this.config.total = res.data.totalcount
            this.tableData = res.data.data.map(item => {
              console.log(
                'type of cailiaotongzhidan_code:',
                typeof item.cailiaotongzhidan_code
              )
              if (typeof item.cailiaotongzhidan_code === 'undefined') {
                item.contract_and_project =
                  item.contract_id + item.contract_project
              } else {
                item.contract_and_project =
                  item.cailiaotongzhidan_code + '#' + item.contract_project
              }

              item.cailiao_and_cailiaoguige =
                item.cailiao_name + '#' + item.cailiaoguige_name
              item.added =
                item.added_person +
                '\n' +
                utils.formatDateTime(item.added_person_time, 'yyyy-MM-dd hh:mm')
              return item
            })
            console.log('chukuxinxi tabledata:', this.tableData)
          } else {
            console.log('没有出库信息！')
          }
          this.config.loading = false
        })
        .catch(function(error) {
          console.log(error)
        })
    },

    //删除库房出库信息
    delKufangchuku(row) {
      this.$confirm('此操作将永久删除此出库信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let id = row.id
          this.$axios
            .get('/deleteChukuxinxi/', {
              params: {
                id
              }
            })
            .then(res => {
              console.log(res.data)
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getTableData()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    //提交库房出库表单
    submitwuziChukuForm(formData) {
      //增加库房出库
      this.chukuForm = formData
      console.log('chukuForm........:', this.chukuForm)
      this.chukuForm.added_person = this.user_realname

      console.log('add chukuForm 从form组件取回的数据:', this.chukuForm)
      this.$axios.post('/addChukuxinxi/', this.chukuForm).then(res => {
        console.log('response:', res.data)
        if (res.data.code === '1000') {
          this.$message({
            type: 'success',
            message: '增加出库信息成功!',
            duration: 4000
          })
          this.resetChukuForm()
          this.getTableData()
        } else {
          this.$message({
            type: 'warning',
            message: res.data.msg,
            duration: 4000
          })
        }
      })
    },
    resetChukuForm() {
      this.chukuDlg_isShow = false
      this.chukuForm = {}
    },
    //在table中显示出库图片
    showPhotosinTableChuku(row) {
      this.chukuphotosurls = []
      let photos = row.photos
      let strs = photos.split(',') //字符分割
      for (let i = 0; i < strs.length; i++) {
        let filename = strs[i].replace('media/images/', '')
        const baseUrl = 'https://jsjz-bak.obs.cn-north-4.myhuaweicloud.com/'
        let url = baseUrl + filename //服务器

        // let url = strs[i] //本地

        this.chukuphotosurls.push(url)
      }
      console.log(this.chukuphotosurls)
      this.chukuPhotos_isShow = true
    },

    doReceive(row) {
      this.receiveForm.id = row.id
      this.receiveChukuDlg_isShow = true
    },
    submitReceiveForm(formData) {
      //修改接收状态
      this.receiveForm.received_photos = formData.photos
      this.receiveForm.received_date = formData.date
      console.log('receiveForm........:', this.receiveForm)
      this.$axios.put('/updateChukuxinxi/', this.receiveForm).then(res => {
        console.log('response:', res.data)
        if (res.data.code === '1000') {
          this.$message({
            type: 'success',
            message: '接收成功!',
            duration: 2000
          })
          this.resetReceiveForm()
          this.getTableData()
        } else {
          this.$message({
            type: 'warning',
            message: res.data.msg,
            duration: 2000
          })
        }
      })
    },
    resetReceiveForm() {
      this.receiveChukuDlg_isShow = false
      this.fileListReceiveChuku = []
      this.receiveForm = {}
    },

    //在table中显示接收单据照片
    showReceivePhotos(row) {
      this.receivechukuphotosurls = []
      let photos = row.received_photos
      let strs = photos.split(',') //字符分割
      for (let i = 0; i < strs.length; i++) {
        let filename = strs[i].replace('media/images/', '')
        const baseUrl = 'https://jsjz-bak.obs.cn-north-4.myhuaweicloud.com/'
        let url = baseUrl + filename //服务器

        // let url = strs[i] //本地

        this.receivechukuphotosurls.push(url)
      }
      console.log(this.receivechukuphotosurls)
      this.receivechukuPhotos_isShow = true
    },
    //出库物资审核
    Wuzi_shenhe(row) {
      this.$confirm('确定通过出库审核吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const api = '/chukuWuzishenhe/'
        this.$axios
          .get(api, {
            params: {
              id: row.id,
              wuzi_shenhe_person: this.user_realname,
              wuzi_shenhe_time: utils.getDateTime()
            }
          })
          .then(res => {
            if (res.data.code === '1000') {
              this.$message({
                type: 'success',
                message: '审核成功！'
              })
              this.getTableData()
            } else {
              this.$message({
                message: '审核失败'
              })
            }
          })
      })
    },
    //接收物资审核
    Wuzi_shenheJieshou(row) {
      this.$confirm('确定通过接收审核吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const api = '/chukuWuzishenheJieshou/'
        this.$axios
          .get(api, {
            params: {
              id: row.id,
              wuzi_shenhe_person: this.user_realname,
              wuzi_shenhe_time: utils.getDateTime()
            }
          })
          .then(res => {
            if (res.data.code === '1000') {
              this.$message({
                type: 'success',
                message: '审核成功！'
              })
              this.getTableData()
            } else {
              this.$message({
                message: '审核失败'
              })
            }
          })
      })
    },
    //设置出库信息显示方式
    setChukuxinxiView(value) {
      this.chukuxinxiview = value
      console.log('this.chukuxinxiview:', this.chukuxinxiview)
      this.getTableData()
    }
  },
  created() {
    let user = utils.getUserCookie()
    this.usertype = user.user_type
    this.user_realname = user.name

    console.log('usertype:', this.usertype)
    console.log('user_realname:', this.user_realname)
    this.config.loading = true
    this.getTableData('')
  }
}
</script>

<style scoped>
.inline {
  display: inline;
}
.right {
  float: right;
}
.left {
  float: left;
}
</style>
